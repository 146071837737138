<template>
<div id="ContactUs">
    <div>
        <h1 class='title'><str index="contact_us.header._text"/></h1>
        <p><str index="contact_us.subheader._text"/></p>

        <v-form ref="contact_us" v-model="validForms.contact_us">
            <v-select v-model="form.subject" :items="subjectItems" :rules="rules.required" required rounded outlined background-color='white'>
                <template v-slot:label>
                    <str index="forms.subject"/>
                </template>
            </v-select>

            <v-text-field v-model="form.given_name" :rules="rules.required" required rounded outlined background-color='white'>
                <template v-slot:label>
                    <str index="forms.given_name"/>
                </template>
            </v-text-field>

            <v-text-field v-model="form.family_name" :rules="rules.required" required rounded outlined background-color='white'>
                <template v-slot:label>
                    <str index="forms.family_name"/>
                </template>
            </v-text-field>

            <v-text-field v-model="form.email" :rules="rules.validEmail" :disabled="$store.getters.user!=undefined" required rounded outlined background-color='white'>
                <template v-slot:label>
                    <str index="forms.email"/>
                </template>
            </v-text-field>

            <v-textarea v-model="form.message" :rules="rules.required" required rounded outlined background-color='white'>
                <template slot="label">
                    <str index="forms.message._text"/>
                </template>
            </v-textarea>
        </v-form>

        <v-card-actions>
            <v-spacer/>
            <v-btn class="submitButton" style='text-transform:initial;' :disabled="busy || !validForms.contact_us" @click="submit">
                <str index="buttons.submit._text"/>
            </v-btn>
            <v-btn v-if="debug" color="error" text @click="ui.success.display=true">Success Message</v-btn>
            <v-spacer/>
        </v-card-actions>
    </div>

    <v-dialog v-model="ui.success.display" width="500" style="overflow-y: visible !important;" persistent>
        <div style="position: relative;">
            <v-card>
                <div style="width: 100%; border: 1px solid green;background-color: green;height: 15px; margin-bottom: 35px;"/>

                <v-card-text style="text-align: center; font-size: 18pt; color: black;">
                    <div style="width: 90%; margin: 0 auto;">
                        <p>
                            <str index="contact_us.success.thanks"/>
                        </p>
                        <br>
                        <p style="font-weight: 300; line-height: 30px;">
                            <str index="contact_us.success.sent" style="color: green;"/>
                        </p>
                        <br>
                        <p style="font-weight: 300; line-height: 30px;">
                            <str index="contact_us.success.response_time"/>
                        </p>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">
                        <v-icon left>mdi-close-circle</v-icon>
                        <str index="buttons.close"/>
                    </v-btn>
                </v-card-actions>
            </v-card>

            <div class="programDecal">
                <div class="programDecal-container">
                    <div class="programDecal-front-fold"/>
                    <div class="programDecal-back-fold"/>
                    <v-icon color="white" style="position: relative; left: 25%; top: 50%; transform: translate(-50%,-50%)">
                        mdi-check-bold
                    </v-icon>
                </div>
            </div>

            <div v-html="cssOverwrite"/>
        </div>
    </v-dialog>

    <v-snackbar v-model="ui.snackbar.display">
        <str v-if="ui.snackbar.message" :index="ui.snackbar.message"/>
        <template v-slot:action="{ attrs }">
            <v-btn @click="ui.snackbar.display = false" v-bind="attrs" color="pink" text>
                <str index="buttons.close"/>
            </v-btn>
        </template>
    </v-snackbar>
</div>
</template>

<script>
import validations from '@/plugins/validations.js'

export default {
    name: "ContactUs",
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        if(this.$store.getters.user){
            this.form.email = this.$store.getters.user.attributes.email
            this.form.given_name = this.$store.getters.user.attributes.given_name
            this.form.family_name = this.$store.getters.user.attributes.family_name
        }
    },
    data: function(){
        return {
            busy: false,
            ui: {
                success: {
                    display: false
                },
                snackbar: {
                    display: false,
                    message: null
                }
            },
            display: false,
            form: {
                subject: null,
                given_name: null,
                family_name: null,
                email: null,
                message: null
            },
            validations: null,
            rules: null,
            validForms: {
                contact_us: false
            }
        }
    },
    methods: {
        submit: async function(){
            let self = this
            self.busy = true
            self.$refs.contact_us.validate()
            if(this.validForms.contact_us){
                self.form.language = this.$store.getters.language
                let result = await self.sendRequest('post','/api/contact_us',self.form)
                if(result.status==200){
                    self.ui.success.display = true
                }else{
                    self.ui.snackbar.display = true
                    self.ui.snackbar.message = "Error sending message"
                }
            }else{
                self.ui.snackbar.display = true
                self.ui.snackbar.message = self.strMap.errors.incomplete
            }
            self.busy = false
        },
        close: function(){
            this.ui.success.display=false
            this.$router.push({name:'Home'})
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        subjectItems: function(){
            let labels = this.labels
            let language = this.language

            let output = [
                {
                    text: labels?.contact_us?.subject_options?.about_technical_issue ? labels.contact_us.subject_options.about_technical_issue._text[language] : 'Missing: labels.contact_us.subject_options.about_technical_issue',
                    value: "about_technical_issue"
                },
                {
                    text: labels?.contact_us?.subject_options?.about_platform ? labels.contact_us.subject_options.about_platform._text[language] : 'Missing: labels.contact_us.subject_options.about_platform',
                    value: "about_platform"
                },
                {
                    text: labels?.contact_us?.subject_options?.other ? labels.contact_us.subject_options.other._text[language] : 'Missing: labels.contact_us.subject_options.other',
                    value: "other"
                }
            ]

            return output
        },
        language: function(){
            return this.$store.getters.language
        },
        labels: function(){
            return this.$store.getters.labels
        },
        strMap: function(){
            return {
                errors: {
                    incomplete: "contact_us.errors.incomplete"
                }
            }
        },
        cssOverwrite: function(){
            return `
            <style type='text/css'>
                .v-dialog.v-dialog--active{
                    overflow-y: visible !important;
                }
            </style>
            `
        },
        allowSubmit: function(){
            let tests = {
                subject: this.subject
            }
        }
    },
    watch: {
        display: function(){
            if(this.display && this.$store.getters.signedIn){
                let attributes = this.$store.getters.user.attributes
                this.form.given_name = attributes.given_name
                this.form.family_name = attributes.family_name
                this.form.email = attributes.email
            }else{
                for(let i in this.form){
                    this.form[i] = null
                }
            }
            this.$emit('input',this.display)
        }
    }
}
</script>

<style scoped lang="scss">

#ContactUs {
    padding: 0 12%;

    @media(max-width: 575px){ // xs < 576
        padding: 0 4%;
    }

    &:before {
        content: '';
        background-image: url('../assets/CanClearLogo_leaf.png');
        background-size: contain;
        opacity: 0.2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 24%;
        left: 8%;

        @media(max-width: 1399px){ // xl < 1400
            left: 0%;
        }
        @media(max-width: 1199px){ // lg < 1200
            height: 80%;
        }
        @media(max-width: 991px){ // md < 992
            left: -8%;
        }
        @media(max-width: 767px){ // sm < 768
            height: 60%;
        }
        @media(max-width: 575px){ // xs < 576
            top: 22%;
            left: -24%;
        }
    }

    .title {
        position: relative;
        top: 32px;
        margin-bottom: 48px;
        text-align: center;
    }

    .v-form {
        padding: 24px 24% 0;

        @media(max-width: 1399px){ // xl < 1400
            padding: 24px 20% 0;
        }
        @media(max-width: 1199px){ // lg < 1200
            padding: 24px 16% 0;
        }
        @media(max-width: 991px){ // md < 992
            padding: 24px 12% 0;
        }
        @media(max-width: 767px){ // sm < 768
            padding: 24px 0% 0;
        }
    }

    .submitButton {
        width: 280px;
        border-radius: 100px;
        background-color: $orange;
        color: white;
    }

    .programDecal{
        position: absolute;
        top: 0px;
        right: 25px;
    }

    .programDecal-container{
        position: relative;
        background-color: $primary;
        width: 50px;
        height: 60px;
    }

    .programDecal-back-fold{
        position: absolute;
        top: -8px;
        right: -6px;
        width: 10px;
        height: 8px;
        background-color: blue;
        z-index: 1;
    }

    .programDecal-front-fold{
        position: absolute;
        top: -8px;
        right: -3px;
        transform: skewX(-40deg);
        width: 100%;
        height: 8px;
        background-color: $primary;
        z-index: 2;
    }
}
</style>
